import { AuditOutlined, HistoryOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Avatar, Badge, Col, Divider, Dropdown, Row, Typography } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import BackButton from 'components/buttons/BackButton';
import GGButton from 'components/buttons/GGButton';
import CaregiverProfileModal from 'components/modals/CaregiverProfileModal';
import AppColors from 'config/AppColors';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'services/RouteService';
import ApiCaregiversManager from 'services/api/ApiCaregiversManager';
import ApiDoctorConversationsManager, { StatusChangeRequestType } from 'services/api/ApiDoctorConversationsManager';
import { conversationActions } from 'store/conversations';
import { selectCurrentConversationId, selectCurrentConversationSummary } from 'store/conversations/selectors';
import { selectCurrentUser } from 'store/users/selectors';
import logoAsset from '../../assets/logo.png';

const { Title } = Typography;

export type RecipientHeaderProps = {
  name?: string;
  avatar?: string | React.ReactNode;
  caregiverId?: string;
  // add some props to control what to display
  showBackButton?: boolean;
  onBackButtonClick?: () => void;
  showSummaryButton?: boolean;
  showHistoryButton?: boolean;
  showActionsButton?: boolean;
};

const useStyles = createUseStyles({
  container: {
    paddingTop: 15,
    paddingLeft: 13,
    paddingRight: 13,
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
    zIndex: 100,
  },
  actionsContainer: {
    padding: '15px 0px',
    backgroundColor: AppColors.main,
    '& button': {
      color: 'white !important',
      '& .ant-badge-count': {
        color: `${AppColors.main} !important`,
      },
    },
    '& .ant-divider': {
      height: '100%',
      borderColor: AppColors.backgroundLightGreen,
    },
  },
  columnBackButton: {
    marginRight: 10,
  },
  columnAvatar: {
    marginRight: 5,
  },
  name: {
    margin: 0,
  },
  caregiverList: {},
  caregiverItem: {
    backgroundColor: AppColors.backgroundGrey,
    borderRadius: 5,
    marginTop: 5,
    cursor: 'pointer',
  },
});

const ConversationTopBar: React.FC<RecipientHeaderProps> = ({
  name = undefined,
  avatar = undefined,
  caregiverId = undefined,
  showBackButton = false,
  onBackButtonClick = undefined,
  showSummaryButton = false,
  showHistoryButton = false,
  showActionsButton = false,
}) => {
  // Hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const [api, context] = useNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selectors
  const currentConversationId = useSelector(selectCurrentConversationId);
  const currentUser = useSelector(selectCurrentUser);
  const currentConversationSummary = useSelector(selectCurrentConversationSummary);

  const urlParams = useParams();
  // TODO this is likely bad coupling between the router and this component's logic
  const isViewingHistoricalConversation = !!urlParams.historicConversation;
  const viewedConversationId = urlParams.historicConversation || currentConversationId;

  // State
  const [openProfileModal, setOpenProfileModal] = useState(false);

  function onBackButtonClicked() {
    if (onBackButtonClick) {
      onBackButtonClick();
    }
  }

  const {
    loading: loadingGetCaregiverGroupsList,
    run: getCaregiverGroupsList,
    data,
  } = useRequest(() => ApiCaregiversManager.getAvailableCaregiverGroupsList(), {
    manual: true,
    onError: () => {
      api.error({
        message: t('caregivers.list.errors.fetch'),
      });
    },
  });

  const { loading: loadingRequestOwnerChange, run: requestOwnerChange } = useRequest(
    caregiverGroupId =>
      ApiDoctorConversationsManager.requestOwnerChange({ caregiverGroupId, conversationId: currentConversationId }),
    {
      manual: true,
      onSuccess: () => {
        api.success({
          message: t('caregivers.actions.requestOwnerChange.success'),
        });
        dispatch(conversationActions.setCurrentConversation(undefined));
        onBackButtonClicked();
      },
      onError: () => {
        api.error({
          message: t('caregivers.actions.requestOwnerChange.error'),
        });
      },
    },
  );

  const { loading: loadingStatusChange, run: requestStatusChange } = useRequest(
    (newStatus: StatusChangeRequestType) =>
      ApiDoctorConversationsManager.requestConversationStatusChange(currentConversationId, newStatus),
    {
      manual: true,
      onSuccess: (coucou, params) => {
        api.success({
          message: t('caregivers.actions.statusChange.success'),
        });
        dispatch(
          conversationActions.setConversationStatus({
            conversationId: currentConversationId,
            newStatus: params[0] === 'CLOSED' ? 'closed' : 'onHold',
          }),
        );
        dispatch(conversationActions.setCurrentConversation(undefined));
        onBackButtonClicked();
      },
      onError: () => {
        api.error({
          message: t('caregivers.actions.statusChange.error'),
        });
      },
    },
  );

  function transfer() {
    getCaregiverGroupsList();
  }

  function selectCaregiverGroup(caregiverGroupId: string) {
    requestOwnerChange(caregiverGroupId);
  }

  const menuProps = {
    items: [
      {
        key: 'put_on_hold',
        label: t('chats.actions.putOnHold'),
      },
      {
        key: 'close',
        label: t('chats.actions.close'),
      },
      {
        key: 'assign',
        label: t('chats.actions.transferTo'),
        children: (data || []).map(d => ({
          key: `assign.${d.id}`,
          label: d.name,
        })),
      },
    ],
    onClick: (e: any) => {
      if (e.key === 'close') requestStatusChange('CLOSED');
      else if (e.key === 'put_on_hold') requestStatusChange('ON_HOLD');
      else if (e.key.startsWith('assign.')) selectCaregiverGroup(e.key.replace('assign.', ''));
    },
  };
  const styleIsClickable = {
    cursor: 'pointer',
  };

  const historyCount = currentConversationSummary?.conversation.patient.number_of_conversations_as_patient || 0;

  function buildActionsBar() {
    const components = [];
    if (showSummaryButton) {
      components.push(
        <GGButton
          type="text"
          size="small"
          icon={<AuditOutlined />}
          onClick={() => {
            if (!isViewingHistoricalConversation)
              navigate(routes.conversationSummary.route.replace(':conversationId', viewedConversationId));
            else
              navigate(
                routes.historicConversationSummary.route
                  .replace(':conversationId', currentConversationId)
                  .replace(':historicConversation', viewedConversationId),
              );
          }}
        >
          {t('chats.actions.summary')}
        </GGButton>,
      );
    }
    if (currentUser?.is_caregiver) {
      if (showHistoryButton) {
        components.push(
          <GGButton
            type="text"
            size="small"
            icon={<HistoryOutlined />}
            onClick={() =>
              navigate(routes.patientConversationsHistory.route.replace(':conversationId', currentConversationId))
            }
          >
            {t('chats.actions.history')}
            {historyCount > 1 && <Badge color="white" count={historyCount} />}
          </GGButton>,
        );
      }
      if (showActionsButton) {
        components.push(
          <Dropdown menu={menuProps}>
            <GGButton
              type="text"
              size="small"
              icon={<PlusCircleOutlined />}
              onClick={() => transfer()}
              disabled={loadingGetCaregiverGroupsList || loadingRequestOwnerChange || loadingStatusChange}
              loading={loadingGetCaregiverGroupsList || loadingRequestOwnerChange || loadingStatusChange}
            >
              {t('chats.actions.button')}
            </GGButton>
          </Dropdown>,
        );
      }
    }
    return components;
  }

  // interleaving vertical dividers
  const actionsBar = buildActionsBar()
    .flatMap(button => [button, <Divider type="vertical" />])
    .slice(0, -1);

  return (
    <>
      <Row className={styles.container}>
        {context}
        {openProfileModal && caregiverId && (
          <CaregiverProfileModal caregiverId={caregiverId} open onClose={() => setOpenProfileModal(false)} />
        )}
        {showBackButton && (
          <Col className={styles.columnBackButton}>
            <BackButton onClick={() => onBackButtonClicked()} />
          </Col>
        )}
        {avatar && (
          <Col
            className={styles.columnAvatar}
            onClick={() => setOpenProfileModal(true)}
            style={caregiverId ? styleIsClickable : undefined}
          >
            {typeof avatar === 'string' && avatar.startsWith('http') ? (
              <Avatar src={avatar} />
            ) : (
              <Avatar src={logoAsset} />
            )}
          </Col>
        )}
        <Col onClick={() => setOpenProfileModal(true)} style={caregiverId ? styleIsClickable : undefined}>
          <Title level={3} className={styles.name}>
            {name}
          </Title>
        </Col>
      </Row>
      {actionsBar.length > 0 && (
        <Row className={styles.actionsContainer}>
          <Col
            flex="auto"
            style={{
              justifyContent: 'space-evenly',
              display: 'flex',
              alignItems: 'start',
            }}
          >
            {actionsBar}
          </Col>
        </Row>
      )}
    </>
  );
};
export default ConversationTopBar;
